var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog__wrapper" },
    [
      _c(
        "Dialog",
        {
          class: _vm.mobileView,
          attrs: { name: _vm.name, width: "728px" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Подтверждение операции")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _vm.isMultiple
              ? _c("div", { staticClass: "dialog__content" }, [
                  _vm._v(
                    " Вы действительно хотите удалить выделенных экспортеров? "
                  ),
                  _c(
                    "ol",
                    _vm._l(_vm.getTableRowParams.namesList, function (item) {
                      return _c("li", { key: item }, [
                        _c("b", [_vm._v(_vm._s(item))]),
                      ])
                    }),
                    0
                  ),
                ])
              : _c("div", { staticClass: "dialog__content" }, [
                  _vm._v(" Вы действительно хотите удалить экспортера "),
                  _c("b", [_vm._v(_vm._s(_vm.getTableRowParams.name) + "?")]),
                ]),
            _c(
              "div",
              { staticClass: "df jc-sb" },
              [
                _c("iq-mobile-button", {
                  attrs: {
                    "button-style":
                      "padding: 12px; width: 100%; margin-right: 4px",
                    "text-style": "color: #000000; font-weight: 500",
                    title: "Нет",
                    disabled: _vm.getIsLoading,
                  },
                  on: {
                    onClick: function ($event) {
                      return _vm.handleRemoveSuppliers(false)
                    },
                  },
                }),
                _c("iq-mobile-button", {
                  attrs: {
                    "button-style":
                      "padding: 12px; width: 100%; margin-left: 4px; background: #F56C6C; border-color: #F56C6C; color: white",
                    "text-style": "color: white; font-weight: 500",
                    title: "Да",
                    loading: _vm.getIsLoading,
                  },
                  on: {
                    onClick: function ($event) {
                      return _vm.handleRemoveSuppliers(true)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }